import React, { useState, useCallback,useEffect } from "react";
import { Grid, Box, Typography } from '@mui/material';
import RadioButtonsGroup from "../@common/Input/radios";
import Button from '@mui/material/Button';
import CustomizedReportTabs from './CustomizedReportTabs';
import DropDownInputSimple from "../@common/Input/DropDownInputSimple/DropDownInputSimple";
import LabelInput from "../@common/Input/LabelInput";
import { options_edo } from '../../utils/optionsEdo';
import { useTranslation } from "react-i18next";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel"
import image from "../../assets/images/reportIcon.svg"
import image2 from "../../assets/images/reportIconsecond.svg"


function MultiReportWithBureau({personalization, setPersonalization, savePersonalization}) {
  const { t } = useTranslation();
  const Tipo = [
    { label: t("labels.radioLabelWithBureau"), value: "With Bureau" },
    { label: t("labels.radioLabelWithoutBureau"), value: "Without Bureau" },
  ];
  const activateLegalFilterList = [
    "Yes",
    "No"
  ];
  const [TipoType, setTipoType] = useState('With Bureau');
  const [legalFiter, setLegalFiter] = useState('Yes');
  const [street, setStreet] = useState(""); //calle
  const [number, setNumber] = useState(""); //number
  const [cologne, setCologne] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [state, setState] = useState("State") //estada
  const [showTextField, setShowTextField] = useState(false);
  const [showTextFieldTwo, setShowTextFieldTwo] = useState(false);
  const [showCustomizedReportTabs, setShowCustomizedReportTabs] = useState(false);
  
  const [showCheckbox, setCheckbox] = useState(false);
  const handleTipoType = useCallback((e) => {
    const newValue = e.target.value;
    if (newValue==="Without Bureau")
    {
        setShowCustomizedReportTabs(true);
    }
    setTipoType(newValue);
 
  }, []);
  const legalFilterHandler = useCallback((e) => {
    setLegalFiter(legalFiter == e.target.value ? "" : e.target.value)
  },
  )

  const toggleCustomizedReportTabs = () => {
    setShowCustomizedReportTabs(!showCustomizedReportTabs);
  };
  const handleCheckboxChange = (event) => {
    setShowTextField(event.target.checked);
  };
  const handleCheckboxTwoChange = (event) => {
    setShowTextFieldTwo(event.target.checked);
  };
  const handleImageClick = () => {
    setCheckbox(!showCheckbox)
  };

  return (
    <Box>
       {!showCustomizedReportTabs  && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <Box sx={{ backgroundColor: "#ffffff", py: 4, px: 6, borderRadius: 2, width: '65%', height: "100%",marginLeft:'20%' }}>
            <Box>
            <Typography onClick={handleImageClick}>
      Add NSS and/or ID (optional):
      {showCheckbox ? (
        <img src={image2} alt="Your Alt Text" style={{ verticalAlign: 'middle', margin: '0 30px' }} />
      ) : (
        <img src={image} alt="Your Alt Text" style={{ verticalAlign: 'middle', margin: '0 30px' }} />
      )}
    </Typography>

{showCheckbox && (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }}
  >
    <Grid item xs={12} sm={6}>
      <Box sx={{ mt: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showTextField}
              onChange={handleCheckboxChange}
            />
          }
          label="SNS (Optional)"
        />
      </Box>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Box sx={{ mt: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showTextFieldTwo}
              onChange={handleCheckboxTwoChange}
            />
          }
          label="ID (Optional)"
        />
      </Box>
    </Grid>
  </Box>
)}

            </Box>
            <Box sx={{display: "flex", // Apply flex display to this container
          flexDirection: "row",
          justifyContent: "center",}}>
            <Grid item xs={12} sm={6} >
          <Box sx={{ mt: 3}}>
            <RadioButtonsGroup
              topLabelName="Activate legal filter:"
              topLabel={true}
              GroupingList={activateLegalFilterList}
              value={legalFiter}
              onChange={legalFilterHandler}
              smGrid={5}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} >
          <Box sx={{ mt: 3 ,fontSize:'12px',marginLeft:'30px' }}>
            <RadioButtonsGroup
              topLabelName="Multi-report type:"
              topLabel={true}
              GroupingList={Tipo}
              value={TipoType}
              onChange={handleTipoType}
              smGrid={6}
            />
          </Box>
        </Grid>
        </Box>
        <Box sx={{display: "flex", // Apply flex display to this container
          flexDirection: "row",
          justifyContent: "center",}}>
        <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 2 }}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label="Street"
                  name="street"
                  id="street"
                  placeholder="Enter street"
                  inputValue={street}
                  onChange={(event) => setStreet(event.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 2,ml:4 }}>
                <LabelInput
                  type="number"
                  subLabel=""
                  label="Number"
                  name="number"
                  id="number"
                  placeholder="Enter number"
                  inputValue={number}
                  onChange={(event) => setNumber(event.target.value)}
                  maxLength={10}
                />
              </Box>
            </Grid>
            </Box>
            <Box sx={{display: "flex", // Apply flex display to this container
          flexDirection: "row",
          justifyContent: "center",}}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 2 }}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label="cologne"
                  name="cologne"
                  id="cologne"
                  placeholder="Enter cologne"
                  inputValue={cologne}
                  onChange={(event) => setCologne(event.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 2,ml:4  }}>
                <LabelInput
                  type="text"
                  subLabel=""
                  label="Mavor’s Office/Municipality"
                  name="office"
                  id="office"
                  placeholder="Enter mavor’s office"
                  inputValue={municipality}
                  onChange={(event) => setMunicipality(event.target.value)}
                />
              </Box>
            </Grid>
            </Box>
            <Box sx={{display: "flex", // Apply flex display to this container
          flexDirection: "row",
          justifyContent: "center",}}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 2 }}>
                <LabelInput
                  type="number"
                  subLabel=""
                  label="Postal Code"
                  name="code"
                  id="code"
                  placeholder="Enter postal code"
                  inputValue={postal_code}
                  onChange={(event) => setPostalCode(event.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 2 ,ml:4 }}>
                <DropDownInputSimple
                  options_data={options_edo}
                  type="text"
                  name="condition"
                  subLabel=""
                  id="condition"
                  label="Condition"
                  onChange={(event) => setState(event.target.value)}
                />
              </Box>
            </Grid>
        </Box>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 4 }}>
              <Button onClick={() => {
                  if (TipoType === "With Bureau") {
                    toggleCustomizedReportTabs();
                  }
                }}   sx={{ backgroundColor: "primary.main", py: 1, px: { xs: 2, sm: 6 }, fontSize: "18px", fontFamily: "Gilroy-Medium", textTransform: "capitalize" }} variant="contained">Continue</Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        )}
        {showCustomizedReportTabs && <CustomizedReportTabs personalization={personalization} setPersonalization={setPersonalization} savePersonalization={savePersonalization} TipoType={TipoType} />}
    </Box>
  );
}

export default MultiReportWithBureau;
