import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import SwitchButton from "../@common/SwitchButton";
import CustomizeReportModal from "../@common/Modal/CustomizeReportModal";
import { sectionCData } from "../../utils/reportsConstants";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../context";
const label = { inputProps: { "aria-label": "Switch demo" } };

function CustomizedReportTabsBody(props) {
  const { personalization, setPersonalization, savePersonalization, sectionData, withBureau = true } = props;
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "primary.main",
          borderRadius: "6px",
          display: { xs: "none", sm: "none", lg: "block" },
        }}
      >
        <Table sx={{ minWidth: 950 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "40px",
                  py: 2,
                  border: 0,
                  borderRight: "solid 1px rgba(112, 112, 112, 0.4)",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "Gilroy-SemiBold",
                    color: "#ffffff",
                  }}
                >
                  {" "}
                  {sectionData.I.titlealphabet}
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ width: "55%", py: 2, border: 0 }}>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "Gilroy-SemiBold",
                    color: "#ffffff",
                    marginRight: "30px !important",
                    '@media (max-width: 1300px)': {
                      width: '50% !Important'
                    }
                  }}
                >
                  {t(`${sectionData.I.maintitle}`)}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "30%", py: 2, border: 0 }} align="left">
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "Gilroy-SemiBold",
                    color: "#ffffff",
                    marginRight: "20px !important",
                    '@media (max-width: 1300px)': {
                      width: '34% !Important'
                    }
                  }}
                >
                  {" "}
                  {t("landingpage.leftContent.tabs.subheading.Concept")}
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%", py: 2, border: 0 }} align="left">
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "Gilroy-SemiBold",
                    color: "#ffffff",
                    marginRight: "60px !important",
                    '@media (max-width: 1300px)': {
                      marginRight: "145px !important",
                    }
                  }}
                >
                  {" "}
                  {t("landingpage.leftContent.tabs.subheading.Action")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ width: "40px" }}></Box>
        <Box sx={{ width: "100%" }}>
          <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
            <Table
              sx={{ borderRadius: 0, }}
              aria-label="simple table"
            >
              <TableBody sx={{ borderRadius: 0 }}>
                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      width: "55%",
                      borderTop: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderBottom: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderRadius: 0,
                    }}
                    align="left"
                  >
                    <Typography
                      sx={{
                        color: "primary.main",
                        fontSize: 14,
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      {t(`${sectionData.I.title}`)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "30%",
                      backgroundColor: "rgba(0, 127, 255, 0.2)",
                      border: "5px solid #ffffff !important",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderRadius: 0,
                    }}
                    align="left"
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {" "}
                        {t(`${sectionData.I.concepto}`)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "15%",
                      borderRadius: 0,
                      border: "5px solid #ffffff !important",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                    }}
                    align="left"
                  >
                    <SwitchButton
                      personalization={personalization}
                      setPersonalization={setPersonalization}
                      section={sectionData.I.titlealphabet}
                      subsection="I"
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      width: "55%",
                      borderTop: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderBottom: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderRadius: 0,
                      backgroundColor: "rgba(0, 127, 255, 0.1)",
                    }}
                    align="left"
                  >
                    <Typography
                      sx={{
                        color: "primary.main",
                        fontSize: 14,
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      {t(`${sectionData.II.title}`)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "30%",
                      borderTop: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderBottom: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderRadius: 0,
                      backgroundColor: "rgba(0, 127, 255, 0.2)",
                    }}
                    align="left"
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {t(`${sectionData.II.concepto}`)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "15%",
                      border: "5px solid #ffffff !important",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderRadius: 0,
                      backgroundColor: "rgba(0, 127, 255, 0.1)",
                    }}
                    align="left"
                  >
                    <SwitchButton
                      personalization={personalization}
                      setPersonalization={setPersonalization}
                      section={sectionData.I.titlealphabet}
                      subsection="II"
                    />
                  </TableCell>
                </TableRow>

                {sectionData.III.titlealphabet !== "G" || withBureau ? (
                  <>
                    <TableRow
                      sx={{
                        borderRadius: 0,
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          borderRadius: 0,
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          width: "55%",
                          borderTop: "5px solid #ffffff",
                          '@media (max-width: 600px)': {
                            p: 1,
                          },
                          borderBottom: "5px solid #ffffff",
                          '@media (max-width: 600px)': {
                            p: 1,
                          },
                          borderRadius: 0,
                        }}
                        align="left"
                      >
                        <Typography
                          sx={{
                            color: "primary.main",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {t(`${sectionData.III.title}`)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "30%",
                          backgroundColor: "rgba(0, 127, 255, 0.2)",
                          border: "5px solid #ffffff !important",
                          '@media (max-width: 600px)': {
                            p: 1,
                          },
                          borderRadius: 0,
                        }}
                        align="left"
                      >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: 14,
                              fontFamily: "Gilroy-Medium",
                            }}
                          >
                            {" "}
                            {t(`${sectionData.III.concepto}`)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "15%",
                          borderRadius: 0,
                          border: "5px solid #ffffff !important",
                          '@media (max-width: 600px)': {
                            p: 1,
                          },
                        }}
                        align="center"
                      >
                        <SwitchButton
                          personalization={personalization}
                          setPersonalization={setPersonalization}
                          section={sectionData.I.titlealphabet}
                          subsection="III"
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}

                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      width: "55%",
                      borderTop: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderBottom: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderRadius: 0,
                      backgroundColor: "rgba(0, 127, 255, 0.1)",
                    }}
                    align="left"
                  >
                    <Typography
                      sx={{
                        color: "primary.main",
                        fontSize: 14,
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      {t(`${sectionData.IV.title}`)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "30%",
                      borderTop: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderBottom: "5px solid #ffffff",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderRadius: 0,
                      backgroundColor: "rgba(0, 127, 255, 0.2)",
                    }}
                    align="left"
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {" "}
                        {t(`${sectionData.IV.concepto}`)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "15%",
                      border: "5px solid #ffffff !important",
                      '@media (max-width: 600px)': {
                        p: 1,
                      },
                      borderRadius: 0,
                      backgroundColor: "rgba(0, 127, 255, 0.1)",
                    }}
                    align="left"
                  >
                    <SwitchButton
                      personalization={personalization}
                      setPersonalization={setPersonalization}
                      section={sectionData.I.titlealphabet}
                      subsection="IV"
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  {sectionData && sectionData.V && sectionData.V.title ? (
                    <TableCell
                      sx={{
                        width: "55%",
                        borderTop: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderBottom: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {t(`${sectionData.V.title}`)}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.V && sectionData.V.concepto ? (
                    <TableCell
                      sx={{
                        width: "30%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {" "}
                          {t(`${sectionData.V.concepto}`)}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.V ? (
                    <TableCell
                      sx={{
                        width: "15%",
                        borderRadius: 0,
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                      }}
                      align="center"
                    >
                      <SwitchButton
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        section={sectionData.I.titlealphabet}
                        subsection="V"
                      />
                    </TableCell>
                  ) : null}
                </TableRow>

                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  {sectionData && sectionData.VI && sectionData.VI.title ? (
                    <TableCell
                      sx={{
                        width: "55%",
                        borderTop: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderBottom: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                        backgroundColor: "rgba(0, 127, 255, 0.1)",
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {t(`${sectionData.VI.title}`)}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.VI && sectionData.VI.concepto ? (
                    <TableCell
                      sx={{
                        width: "30%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {" "}
                          {t(`${sectionData.VI.concepto}`)}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.VI ? (
                    <TableCell
                      sx={{
                        width: "15%",
                        borderRadius: 0,
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        backgroundColor: "rgba(0, 127, 255, 0.1)",
                      }}
                      align="center"
                    >
                      <SwitchButton
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        section={sectionData.I.titlealphabet}
                        subsection="VI"
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  {sectionData && sectionData.VII && sectionData.VII.title ? (
                    <TableCell
                      sx={{
                        width: "55%",
                        borderTop: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderBottom: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {t(`${sectionData.VII.title}`)}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {sectionData &&
                    sectionData.VII &&
                    sectionData.VII.concepto ? (
                    <TableCell
                      sx={{
                        width: "30%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {" "}
                          {t(`${sectionData.VII.concepto}`)}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.VII ? (
                    <TableCell
                      sx={{
                        width: "15%",
                        borderRadius: 0,
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                      }}
                      align="center"
                    >
                      <SwitchButton
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        section={sectionData.I.titlealphabet}
                        subsection="VII"
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  {sectionData && sectionData.VIII && sectionData.VIII.title ? (
                    <TableCell
                      sx={{
                        width: "55%",
                        borderTop: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderBottom: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                        backgroundColor: "rgba(0, 127, 255, 0.1)",
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {t(`${sectionData.VIII.title}`)}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {sectionData &&
                    sectionData.VIII &&
                    sectionData.VIII.concepto ? (
                    <TableCell
                      sx={{
                        width: "30%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {" "}
                          {t(`${sectionData.VIII.concepto}`)}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.VIII ? (
                    <TableCell
                      sx={{
                        width: "15%",
                        borderRadius: 0,
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        backgroundColor: "rgba(0, 127, 255, 0.1)",
                      }}
                      align="center"
                    >
                      <SwitchButton
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        section={sectionData.I.titlealphabet}
                        subsection="VIII"
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  {sectionData && sectionData.IX && sectionData.IX.title ? (
                    <TableCell
                      sx={{
                        width: "55%",
                        borderTop: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderBottom: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {t(`${sectionData.IX.title}`)}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.IX && sectionData.IX.concepto ? (
                    <TableCell
                      sx={{
                        width: "30%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {" "}
                          {t(`${sectionData.IX.concepto}`)}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.IX ? (
                    <TableCell
                      sx={{
                        width: "15%",
                        borderRadius: 0,
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                      }}
                      align="center"
                    >
                      <SwitchButton
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        section={sectionData.I.titlealphabet}
                        subsection="IX"
                      />
                    </TableCell>
                  ) : null}
                </TableRow>

                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  {sectionData && sectionData.X && sectionData.X.title ? (
                    <TableCell
                      sx={{
                        width: "55%",
                        borderTop: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderBottom: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                        backgroundColor: "rgba(0, 127, 255, 0.1)",
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {t(`${sectionData.X.title}`)}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.X && sectionData.X.concepto ? (
                    <TableCell
                      sx={{
                        width: "30%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {" "}
                          {t(`${sectionData.X.concepto}`)}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.X ? (
                    <TableCell
                      sx={{
                        width: "15%",
                        borderRadius: 0,
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        backgroundColor: "rgba(0, 127, 255, 0.1)",
                      }}
                      align="center"
                    >
                      <SwitchButton
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        section={sectionData.I.titlealphabet}
                        subsection="X"
                      />
                    </TableCell>
                  ) : null}
                </TableRow>

                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  {sectionData && sectionData.XI && sectionData.XI.title ? (
                    <TableCell
                      sx={{
                        width: "55%",
                        borderTop: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderBottom: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {t(`${sectionData.XI.title}`)}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.XI && sectionData.XI.concepto ? (
                    <TableCell
                      sx={{
                        width: "30%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {" "}
                          {t(`${sectionData.XI.concepto}`)}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.XI ? (
                    <TableCell
                      sx={{
                        width: "15%",
                        borderRadius: 0,
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                      }}
                      align="center"
                    >
                      <SwitchButton
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        section={sectionData.I.titlealphabet}
                        subsection="XI"
                      />
                    </TableCell>
                  ) : null}
                </TableRow>

                <TableRow
                  sx={{
                    borderRadius: 0,
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  {sectionData && sectionData.XII && sectionData.XII.title ? (
                    <TableCell
                      sx={{
                        width: "55%",
                        borderTop: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderBottom: "5px solid #ffffff",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                        backgroundColor: "rgba(0, 127, 255, 0.1)",
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        {t(`${sectionData.XII.title}`)}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {sectionData &&
                    sectionData.XII &&
                    sectionData.XII.concepto ? (
                    <TableCell
                      sx={{
                        width: "30%",
                        backgroundColor: "rgba(0, 127, 255, 0.2)",
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        borderRadius: 0,
                      }}
                      align="left"
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          {" "}
                          {t(`${sectionData.XII.concepto}`)}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : null}

                  {sectionData && sectionData.XII ? (
                    <TableCell
                      sx={{
                        width: "15%",
                        borderRadius: 0,
                        border: "5px solid #ffffff !important",
                        '@media (max-width: 600px)': {
                          p: 1,
                        },
                        backgroundColor: "rgba(0, 127, 255, 0.1)",
                      }}
                      align="center"
                    >
                      <SwitchButton
                        personalization={personalization}
                        setPersonalization={setPersonalization}
                        section={sectionData.I.titlealphabet}
                        subsection="XII"
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          mt: 4,
        }}
      >
        <CustomizeReportModal personalization={personalization} setPersonalization={setPersonalization} savePersonalization={savePersonalization} />
      </Box>
    </Box>
  );
}

export default CustomizedReportTabsBody;
