import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import failedImage from "../../../assets/images/errorIcon.svg";
import successImage from "../../../assets/images/thankimage.png";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function AlertModal(props) {
  const { text, onClose, thanks, closedModalFunction, closedModal } = props;
  const { t } = useTranslation();
  // If the text prop is not provided, don't render the modal
  if (!text) {
    return null;
  }

  const handleButtonClick = () => {
    if (closedModal) {
      closedModalFunction();
      onClose();
    } else {
      onClose();
    }
  };

  return (
    <Fragment>
      <Modal
        open={true} // Open the modal whenever it is rendered
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={thanks ? successImage : failedImage}
              width="68px"
              height="68px"
              alt="email icon"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: 22,
                fontFamily: "Gilroy-Medium",
                color: "neutral.900",
                textAlign: "center",
              }}
            >
              {text}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              onClick={handleButtonClick}
              sx={{
                backgroundColor: "primary.main",
                py: 1,
                px: { xs: 4, sm: 8 },
                fontSize: "18px",
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              variant="contained"
            >
              {t("CouponsManager.popUpButton")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
