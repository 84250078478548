import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { call_api_function } from "../../../utils/verifyAPICalls";
import { getCookiesList } from "../../../utils/cookiesHelper";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 17,
    height: 17,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function SwitchButton({ personalization, setPersonalization, section, subsection }) {
  const [checked, setChecked] = useState(true);
  const [cookieList, setCookieList] = useState([]);
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);
  useEffect(() => {
    setChecked(personalization[section] && personalization[section][subsection]);
  }, [cookieList]);

  const handleChange = (event) => {
    const isChecked = event.target.checked;

    personalization[section] = personalization[section] || {};
    personalization[section][subsection] = isChecked;

    setPersonalization(personalization);

    setChecked(isChecked);
  };

  return (
    <FormGroup
      sx={{
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
      }}
    >
      <FormControlLabel
        control={
          <Switch sx={{ m: 1 }} checked={checked} onChange={handleChange} />
        }
      />
    </FormGroup>
  );
}
