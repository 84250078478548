import React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import CustomizedReport from '../CustomizedReport/CustomizedReport';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxWidth: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  maxHeight: '90vh',
  overflow: 'auto'
};
const customStyle =  {
  overflow: 'hidden',
}

function CustomizedReportModalShow({ showCustomizedReportModal, setCustomizedReportModal, isForAllUsers }) {
  const { t } = useTranslation();
  useEffect(() => {
    if(isForAllUsers == true) {
        localStorage.setItem('isForAllUsers', isForAllUsers)
    }
  },[])

  const handleClose = () => {
    setCustomizedReportModal(false);
  };

  return (
    <Modal
      open={showCustomizedReportModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={customStyle}>
          <CustomizedReport />
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 2,
          mt: 2,
          mb: 1
        }}>
          <Button
            onClick={handleClose}
            style={{
              color: "rgba(27, 32, 44, 0.6)",
              fontSize: 16,
              fontFamily: "Gilroy-Medium",
              textDecoration: "underline",
              textTransform: "capitalize"
            }}
            variant="text"
          >
            {t("buttons.back")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CustomizedReportModalShow;