import React from "react";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  position: 'relative',
  '& .MuiSwitch-root': {
    width: 90,
    height: 42,
    padding: 0,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 28,
    },
    '& .MuiSwitch-switchBase': {
      padding: 1,
      '&.Mui-checked': {
        transform: 'translateX(48px)',
        [theme.breakpoints.down('sm')]: {
          transform: 'translateX(32px)',
        },
        color: theme.palette.common.white,
        '& + .MuiSwitch-track': {
          opacity: 1,
          border: 'none',
          backgroundColor: '#FF8D28',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: 40,
      height: 40,
      [theme.breakpoints.down('sm')]: {
        width: 26,
        height: 26,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      borderRadius: 50,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.action.selected
          : "#DBDDDE",
      border: '1px solid #DBDDDE',
      transition: theme.transitions.create(['background-color', 'border']),
    },
  },
}));

const SwitchUserPanel = ({ isToggled, onToggle, customizationTitle }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: 'space-between',
      alignItems: "center",
      mb: 2,
      position: "relative",
    }}>
      <Typography sx={{
        fontSize: {
          xs: 14,
          sm: 16
        },
        color: "rgba(33, 33, 33, 0.6)",
        mr: 4
      }}>
        {t(customizationTitle)}
      </Typography>
      <Box sx={{'@media (max-width: 400px)': { marginLeft: '-30px' }}}>
      <FormControlLabel
          className="my-parent-class"
          control={
            <Switch
              className="switch-childClass"
              checked={isToggled}
              onChange={() => onToggle(!isToggled)}
            />
          }
          labelPlacement="start"
          label={
            <Box sx={{
              position: 'absolute',
              bottom: { xs: 4, sm: 8 },
              zIndex: 3,
              display: 'flex'
            }}>
              <Box
                component="span"
                sx={{
                  display: isToggled ? 'block' : 'none',
                  color: "#ffffff",
                  width: { xs: 40, sm: 60 },
                  textAlign: "center",
                  fontSize: { xs: 12, sm: 15 }
                }}
              >
                {t("GuestDashboard.companyInfoButtonYes")}
              </Box>
              <Box
                component="span"
                sx={{
                  display: isToggled ? 'none' : 'block',
                  color: "#707070",
                  width: { xs: 80, sm: 120 },
                  textAlign: "center",
                  fontSize: { xs: 12, sm: 15 }
                }}
              >
                {t("GuestDashboard.companyInfoButtonNo")}
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default SwitchUserPanel;