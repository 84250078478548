import { Grid, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import underLine from "../../assets/images/under-line.png";
import CompanyInformationCard from "./CompanyInformationCard";
import videoIcon from "../../assets/images/profile_Icon.svg";
import profilePic from "../../assets/images/profile-pic.svg";
import ProfileModal from "../@common/Modal/ProfileModal";
import ProfileModalSecond from "../@common/Modal/ProfileModalSecond";
import sjcl from "sjcl";
import axios from "axios";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { encryptData } from "../../utils/encryptionHelper";
import { call_api_function } from "../../utils/verifyAPICalls";
import { serverRequest, serverRequest2 } from "../../utils/requestHelper";
import AlertModal from "../@common/Modal/AlertModal";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../context";
function CompanyInformation() {
  const { t } = useTranslation();
  const { isCustomizationEnabled: globalCustomizationEnabled, isUserPanelsVisible: globalUserPanelsVisible, setCustomizationEnabled: setGlobalCustomizationEnabled, setUserPanelsVisible: setGlobalUserPanelsVisible } = useGlobalContext();
  const [adminModalOpen, setAdminModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [isCustomizationEnabled, setCustomizationEnabled] = useState(globalCustomizationEnabled);
  const [isUserPanelsVisible, setUserPanelsVisible] = useState(globalUserPanelsVisible);

  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  // const [companyDetails, setCompanyDetails] = useState({});
  const [role, setRole] = useState("");
  const userInfo = Cookies.get("userInfo");
  const cookies = userInfo.split(",");
  const email = cookies[3];
  const [image, setimage] = useState("");
  const [logoimage, setlogoimage] = useState("");
  const userRole = cookies[12];
  const isSuperuser = userRole !== "usuario";

  const adminhandler = () => {
    if (isSuperuser) {
      setAdminModalOpen(true);
      console.log("true:")
    }
  };

  const profileHandler = () => {
    setProfileModalOpen(true);
    getProfile();
  };
  useEffect(() => {
    const data_eel = encryptData(email, 500);
    var dataForm = {
      data_eel: {
        data_eeli: data_eel["iv"],
        data_eels: data_eel["salt"],
        data_eelc: data_eel["ct"],
      },
    };
    getCompanyDetails(dataForm);
  }, [email]);

  const handleCustomizationToggle = (newValue) => {
    setCustomizationEnabled(newValue);
  };
  const handlePanelsVisibleToggle = (newValue) => {
    setUserPanelsVisible(newValue);
  };

  const getCompanyDetails = async (dataForm) => {
    if (cookies[12] == "manager") {
      setRole("Super Admin");
    } else if (cookies[12] == "admin") {
      setRole("Admin");
    } else if (cookies[12] == "usuario") {
      setRole("User");
    }
    try {
      const response = await serverRequest(
        "post",
        "perfil/get_datos_empresa",
        dataForm
      );
      if (response.data) {
        cookies[15] = response.data["direccion"];
        cookies[16] = response.data["correo_contacto"];
        cookies[17] = response.data["telefono_contacto"];
        Cookies.set("userInfo", cookies.join(","));
        const customizationEnabled = response.data["can_customize_users"] || false
        const userPanelsVisible = response.data["can_view_users_panel"] || false
        setGlobalCustomizationEnabled(customizationEnabled);
        setGlobalUserPanelsVisible(userPanelsVisible);
        setCustomizationEnabled(customizationEnabled);
        setUserPanelsVisible(userPanelsVisible);
        if (cookies[12] == "manager") {
          localStorage.setItem("customizationEnabled", customizationEnabled);
          localStorage.setItem("userPanelsVisible", userPanelsVisible);
        } else if (cookies[12] == "admin") {
          localStorage.setItem("customizationEnabled", customizationEnabled);
          localStorage.setItem("userPanelsVisible", userPanelsVisible);
        }
      } else {
        setShowAlert(true);
        setShowtext(t("thanks.check"));
      }
    } catch (e) {
      setShowAlert(true);
      setShowtext("error", e);
    }
  };
  const getProfile = async () => {
    try {
      const formdata = new FormData();
      formdata.append("email", email);
      const res = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "get-user-image",
        formdata
      );
      setimage(res.data);
    } catch (e) {
      setShowAlert(true);
      setShowtext("error", e);
    }
  };
  useEffect(() => {
    getProfile();
  }, [email]);
  const getlogo = async () => {
    try {
      const data_eel = encryptData(email, 500);
      var dataForm = {
        data_eel: {
          data_eeli: data_eel["iv"],
          data_eels: data_eel["salt"],
          data_eelc: data_eel["ct"],
        },
      };
      const res = await serverRequest2("post", "perfil/get_logo", dataForm);
      setlogoimage(res.data.url_logo);
    } catch (e) {
      setShowAlert(true);
      setShowtext("error", e);
    }
  };
  useEffect(() => {
    getlogo();
  }, [email]);
  const handleImagelogoUpdate = (updatedImage) => {
    setlogoimage(updatedImage);
    getlogo();
  };
  const handleImageUpdate = (updatedImage) => {
    setimage(updatedImage); // Update the image in the state
    getProfile(); // Fetch the updated image using getProfile function
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  return (
    <>
      <Grid container spacing={0}>
        {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
        <Grid item xs={12} sm={12}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                lineHeight: "35px",
                textAlign: "center",
                color: "#1C212D",
              }}
            >
              {t("PageName.Company")}
            </Typography>
            <Box sx={{ textAlign: "center", mt: -1 }}>
              <img src={underLine} alt="under line" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
        }}
      >
        <CompanyInformationCard
          titleMainTwo={role}
          line={true}
          titleMain={cookies[13]}
          subTitle={cookies[15]}
          emailText={cookies[16]}
          phone={cookies[17]}
          mainImage={logoimage}
          onClick={adminhandler}
          isAdminButtonDisabled={!isSuperuser}
          role={userRole}
          onCustomizationToggle={handleCustomizationToggle}
          isCustomizationEnabled = {isCustomizationEnabled}
          onPanelsVisibleToggle={handlePanelsVisibleToggle}
          isUserPanelsVisible = {isUserPanelsVisible}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
        }}
      >
        <CompanyInformationCard
          titleMainTwo=""
          line={false}
          titleMain={cookies[11]}
          subTitle=""
          emailText={cookies[3]}
          phone={cookies[5]}
          mainImage={image}
          onClick={profileHandler}
        />
      </Box>
      <>
        <ProfileModal
          adminModalOpen={adminModalOpen}
          setAdminModalOpen={setAdminModalOpen}
          email={cookies[3]}
          phone={cookies[5]}
          titleMain={cookies[13]}
          subTitle={cookies[15]}
          emailEmpresa={cookies[16]}
          phoneEmpresa={cookies[17]}
          handleImagelogoUpdate={handleImagelogoUpdate}
        />
        <ProfileModalSecond
          setProfileModalOpen={setProfileModalOpen}
          profileModalOpen={profileModalOpen}
          onImageUpdate={handleImageUpdate}
        />
      </>
    </>
  );
}

export default CompanyInformation;
